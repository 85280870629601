import { router, usePage } from '@inertiajs/vue3'
import Pagination from '@/Classes/Pagination'
import Parameter from '@/Classes/Parameter'
import System from '@/Classes/System'
import Tenant from '@/Classes/Tenant'
import User from '@/Classes/User'

export function getCurrentData() {
    return usePage().props.data
}

export function getCurrentProps() {
    return usePage().props
}

export function getCurrentCombos() {
    return usePage().props.combos
}

export function getCombo(comboName) {
    return usePage().props.combos[comboName]
}

export function getCurrentMenus() {
    return getCurrentProps().menu
}

export function getCurrentPagination() {
    return {
        filter: getCurrentData().filter,
        descending: getCurrentData().descending,
        sortBy: getCurrentData().sortBy,
        pagination: new Pagination(getCurrentData().pagination)
    }
}

export function getCurrentParameters() {
    return new Parameter(usePage().props.parameters)
}

export function getCurrentRoutesPermissions() {
    return getCurrentProps().routes_permissions
}

export function getCurrentSystem() {
    return new System(usePage().props?.tenant?.system)
}

export function getCurrentTenant() {
    return new Tenant(usePage().props.tenant)
}

export function getCurrentUser() {
    return new User(usePage().props.auth.user)
}

export function getCurrentUserPermissions() {
    return getCurrentUser().permissions
}

export function getCurrentUserTenantSpaces() {
    return getCurrentUser().tenant_spaces
}

export function getCurrentVersion() {
    return usePage().props.version
}

export function getInertiaRouter() {
    return router
}
