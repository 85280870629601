import route from 'ziggy-js'
import { getCurrentProps } from '@/Utils/InertiaResponse'

export default function ziggyRoute(
    name,
    params = undefined,
    absolute = undefined,
    config = getCurrentProps().ziggy
) {
    return route(name, params, absolute, config)
}
