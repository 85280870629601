<script setup>
import route from '@/Utils/ZiggyRoute'
import { getInertiaRouter } from '@/Utils/InertiaResponse'
import { useApplication } from '@/Composables/UseApplication'

//Ex.:
//<SharedBreadcrumbs :crumbs="[{ label: 'Papéis', icon: 'import_contacts', route: 'roles.index' }, { label: 'Papel', route: 'roles.show', param: 1 }, { label: 'Auditoria', icon: 'remove_red_eye' }]" />

const isLast = (index) => {
    return useApplication.crumbs?.length > 1
        ? index === useApplication.crumbs?.length - 1
        : false
}

const navigateTo = (url, params = {}) => {
    getInertiaRouter().get(route(url, params))
}
</script>

<template>
    <!-- Quando só tem um item na lista de migalhas de pão -->
    <q-breadcrumbs v-if="useApplication.crumbs?.length === 1">
        <template #separator>
            <q-icon name="arrow_forward" size="1.2em" />
        </template>
        <q-breadcrumbs-el
            v-for="(crumb, index) in useApplication.crumbs"
            :key="crumb.label"
            :disable="isLast(index)"
            :icon="crumb.icon ?? undefined"
            :label="crumb.label"
        />
    </q-breadcrumbs>
    <!-- Quando tem mais de um item na lista de migalhas de pão -->
    <q-breadcrumbs v-else active-color="">
        <template #separator>
            <q-icon name="arrow_forward" size="1.2em" />
        </template>
        <q-breadcrumbs-el
            v-for="(crumb, index) in useApplication.crumbs"
            :key="crumb.label"
            :disable="isLast(index)"
            :href="crumb.route ? route(crumb.route) : undefined"
            :icon="crumb.icon ?? undefined"
            :label="crumb.label"
            target="_self"
            @click.prevent="
                crumb.route ? navigateTo(crumb.route, crumb.param) : undefined
            "
        />
    </q-breadcrumbs>
</template>
