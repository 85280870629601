<script setup>
import route from '@/Utils/ZiggyRoute'
import { getInertiaRouter } from '@/Utils/InertiaResponse'

const currentRouteIs = (routeName) => {
    return route().current(routeName)
}

const menuClick = (go) => {
    getInertiaRouter().get(go)
}
</script>
<style scoped>
.navBarDown {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-color-gray {
    background-color: #fafafa;
}
</style>
<template>
    <div>
        <q-footer bordered class="bg-color-gray text-white q-pa-md">
            <div class="row">
                <div class="col-4 text-right">
                    <q-btn
                        :color="
                            currentRouteIs(
                                'souprodigital.external.dashboard.index'
                            )
                                ? 'primary'
                                : 'grey-3'
                        "
                        class="text-black"
                        icon="home"
                        round
                        @click="
                            menuClick(
                                route('souprodigital.external.dashboard.index')
                            )
                        "
                    />
                </div>
                <div class="col-4 text-center">
                    <q-btn
                        :color="
                            currentRouteIs('souprodigital.external.document.*')
                                ? 'primary'
                                : 'grey-3'
                        "
                        class="text-black"
                        icon="web"
                        round
                        @click="
                            menuClick(
                                route('souprodigital.external.document.index')
                            )
                        "
                    />
                </div>
                <div class="col-4 text-left">
                    <q-btn
                        :color="
                            currentRouteIs('souprodigital.external.profile.*')
                                ? 'primary'
                                : 'grey-3'
                        "
                        class="text-black"
                        icon="account_circle"
                        round
                        @click="
                            menuClick(
                                route('souprodigital.external.profile.index')
                            )
                        "
                    />
                </div>
            </div>
        </q-footer>
    </div>
</template>
