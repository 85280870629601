import { reactive } from 'vue'
import { userCan } from '@/Utils/CheckPermission'
import { useForm } from '@inertiajs/vue3'
import {
    getCurrentData,
    getCurrentParameters,
    getCurrentProps,
    getCurrentRoutesPermissions,
    getInertiaRouter
} from '@/Utils/InertiaResponse'
import route from '@/Utils/ZiggyRoute'
import { Dialog, Loading, QSpinnerTail } from 'quasar'
import { toCommaSeparateString } from '@/Utils/Arrays'
import { showConfirm, showWarningNotify } from '@/Utils/Message'
import Parameter from '@/Classes/Parameter'
import axios from 'axios'

/**
 * Composable base da aplicação, usado em páginas e forms
 */
export const useApplication = reactive({
    /**
     * Titulo da página
     */
    title: '',
    /**
     * Lista de breadcrumbs da página
     */
    crumbs: [],
    /**
     * Objeto com os parâmetros do sistema
     */
    parameters: Parameter,
    /**
     * Variável interna apenas para controle de rerender
     */
    rerenderHelper: 0,
    /**
     * Objeto com as propriedades do formulário
     */
    form: {
        /**
         * Objeto do Inertia para controle do formulário, possui comportamentos que auxiliam no desenvolvimento
         */
        inertiaForm: {},
        /**
         * Objeto com as propriedades do FAB (Floating Action Button), usado nos forms
         */
        fab: {},
        /**
         * Objeto com os erros do formulário, usado para identificar se o campo possui erro, é setado no app.js no finish da requisição
         */
        errors: {},
        /**
         * Boleano que indica se o formulário está em modo de visualização, quando esta no criando o valor deve ser false
         */
        showing: false,
        /**
         * Lista de tabs que será usada no formulário
         */
        tabs: [],
        /**
         * Lista de formulários filhos que estão abertos
         */
        openSubForms: []
    },
    /**
     * Lista de rotas de um determinado prefixo (Ex.: roles)
     */
    applicationRoutes: [],
    /**
     * Método para inicializar o composable
     * @param title Titulo que será usado na página
     * @param crumbs Lista de breadcrumbs que será usada na página
     * @param customRouteCrumbs
     */
    initialize: (title = 'Página', crumbs = [], customRouteCrumbs = '') => {
        useApplication.buildCurrentCrumbs(title, customRouteCrumbs)
        useApplication.title = title
        useApplication.crumbs =
            crumbs?.length > 0 ? crumbs : useApplication.crumbs
        useApplication.applyParameters()
    },
    /**
     * Método para inicializar as rotas vinculadas as permissões de um determinado prefixo (Ex.: roles)
     * O prefixo é obtido através da rota atual
     */
    initializeApplicationRoutes: () => {
        const routePrefixName = route().current().split('.')[0]
        useApplication.applicationRoutes = []
        if (routePrefixName.trim() === '') {
            return
        }
        const routes = Object.keys(getCurrentProps().ziggy.routes)
        let prefixRoutes = routes.filter((route) => {
            return route.startsWith(routePrefixName)
        })
        const permissions = getCurrentRoutesPermissions()[routePrefixName]
        prefixRoutes.forEach((route) => {
            const routeAction = route.split('.')[1]
            useApplication.applicationRoutes[routeAction] = {
                name: route,
                permissions:
                    permissions !== undefined
                        ? permissions[route.split('.')[1]]
                        : []
            }
        })
    },
    /**
     * Método para retornar as permissões de um determinado prefixo de rota. Ex.: index
     * @param prefixRouteName o nome do prefixo da rota
     * @returns {[]|Permissions|*} retorna um array de permissões caso seja passada um nome de rota
     */
    getPrefixPermissionsFromApplicationRoutes: (prefixRouteName) => {
        if (prefixRouteName.trim() === '') {
            return
        }
        return useApplication.applicationRoutes[prefixRouteName].permissions
    },
    /**
     * Método para inicializar o objeto form
     * @param tabs lista de tabs que será usada no formulário
     * @param data objeto de dados da página, o padrão é o objeto data da página atual
     */
    initializeForm: (tabs, data = getCurrentData()) => {
        useApplication.form = {
            inertiaForm: useForm(data),
            fab: {
                back: {
                    id: 'back',
                    label: 'Voltar',
                    show: true,
                    open: true,
                    icon: 'arrow_back',
                    color: 'negative',
                    action: () => {
                        if (useApplication.form.inertiaForm.isDirty) {
                            Dialog.create({
                                title: 'Atenção!',
                                message:
                                    'Deseja realmente sair? Você perderá os dados não salvos.',
                                cancel: true,
                                persistent: true
                            }).onOk(() => {
                                getInertiaRouter().get(
                                    route(useApplication.getIndexRoute())
                                )
                            })
                        } else {
                            getInertiaRouter().get(
                                route(useApplication.getIndexRoute())
                            )
                        }
                    }
                },
                main: {
                    id: 'save',
                    label: 'Salvar',
                    show: false,
                    icon: 'check',
                    color: 'green',
                    action: () => {
                        console.log('Ação não implementada!')
                    }
                },
                others: [
                    // {
                    //     id: 'other',
                    //     label: 'Outra ação',
                    //     show: false,
                    //     icon: 'check',
                    //     color: 'orange',
                    //     action: () => {
                    //         console.log('Ação não implementada!')
                    //     }
                    // }
                ]
            },
            errors: {},
            showing: false,
            tabs: tabs,
            openSubForms: []
        }
    },
    /**
     * Método para resetar as tabs do formulário
     */
    resetFormTabs: () => {
        useApplication.form.tabs = []
    },
    /**
     * Checa se o usuário atual pode atualizar na rota atual
     * @param routesNames A lista de rotas para obter as permissões. Caso não seja passada, será usada a lista de permissões da rota de update
     * @returns {boolean} true se o usuário pode atualizar, false se não pode
     */
    userCanUpdate: (routesNames = []) => {
        let permissionsToCheck = []

        if (routesNames.length === 0) {
            permissionsToCheck =
                useApplication.applicationRoutes['update']?.permissions
        } else {
            routesNames.forEach((route) => {
                useApplication.applicationRoutes[route]?.permissions.forEach(
                    (permission) => {
                        permissionsToCheck.push(permission)
                    }
                )
            })
        }

        return userCan(permissionsToCheck)
    },
    /**
     * Checa se o usuário atual pode criar na rota atual
     * @param routesNames A lista de rotas para obter as permissões. Caso não seja passada, será usada a lista de permissões da rota de store
     * @returns {boolean} true se o usuário pode criar, false se não pode
     */
    userCanStore: (routesNames = []) => {
        let permissionsToCheck = []

        if (routesNames.length === 0) {
            permissionsToCheck =
                useApplication.applicationRoutes['store']?.permissions
        } else {
            routesNames.forEach((route) => {
                useApplication.applicationRoutes[route]?.permissions.forEach(
                    (permission) => {
                        permissionsToCheck.push(permission)
                    }
                )
            })
        }

        return userCan(permissionsToCheck)
    },
    /**
     * Checa se o usuário atual pode visualizar na rota atual
     * @returns {boolean} true se o usuário pode visualizar, false se não pode
     */
    userCanShow: () => {
        return userCan(useApplication.applicationRoutes['show']?.permissions)
    },
    /**
     * Checa se o usuário atual pode excluir na rota atual
     * @returns {boolean} true se o usuário pode excluir, false se não pode
     */
    userCanDestroy: () => {
        return userCan(useApplication.applicationRoutes['destroy']?.permissions)
    },
    /**
     * Checa se o usuário atual pode excluir múltiplos registros na rota atual
     * @returns {boolean} true se o usuário pode excluir múltiplos registros, false se não pode
     */
    userCanMultipleDestroy: () => {
        return userCan(
            useApplication.applicationRoutes['multiple_destroy']?.permissions
        )
    },
    /**
     * Checa se o formulário está apenas em modo de visualização
     * @param routeNames A lista de rotas para obter as permissões. Caso não seja passada, será usada a lista de permissões da rota de update
     * @returns {boolean} true se o formulário está apenas em modo de visualização, false se não está
     */
    formInOnlyViewMode: (routeNames = []) => {
        return (
            useApplication.form.showing &&
            !useApplication.userCanUpdate(routeNames)
        )
    },
    /**
     * Força a renderização de um componente
     */
    forceRerender: () => {
        useApplication.rerenderHelper = Date.now()
    },
    /**
     * Retorna o modo de seleção padrão da grid online.
     * Considera as permissões do usuário relacionadas a exclusão
     * @returns {string} retorna 'multiple', 'single' ou 'none'
     */
    getDefaultGridOnlineSelectionMode: () => {
        return useApplication.userCanMultipleDestroy()
            ? 'multiple'
            : useApplication.userCanDestroy()
            ? 'single'
            : 'none'
    },
    /**
     * Retorna o modo de seleção padrão da grid offline.
     * Considera se o form esta em modo de visualização
     * @returns {string} retorna 'multiple' ou 'none'
     */
    getDefaultGridOfflineSelectionMode: () => {
        return !useApplication.formInOnlyViewMode() ? 'multiple' : 'none'
    },
    /**
     * Retorna se o botão de criar da grid offline deve ser exibido
     * Considera se o form esta em modo de visualização
     * @returns {boolean} retorna true ou false
     */
    getDefaultGridOfflineShowCreateButton: () => {
        return !useApplication.formInOnlyViewMode()
    },
    /**
     * Retorna se o botão de excluir da grid offline deve ser exibido
     * Considera se o form esta em modo de visualização
     * @returns {boolean} retorna true ou false
     */
    getDefaultGridOfflineShowDestroyButton: () => {
        return !useApplication.formInOnlyViewMode()
    },
    /** Executa o comportamento padrão do botão de excluir da grid offline
     * @param event o evento do click
     * @param rows as linhas selecionadas
     * @param listName o nome da lista
     * @param confirmMessage a mensagem de confirmação
     */
    executeDefaultGridOfflineDestroyButtonClick: (
        event,
        rows,
        listName,
        confirmMessage
    ) => {
        showConfirm(
            () => {
                useApplication.form.inertiaForm[listName] =
                    useApplication.form.inertiaForm[listName].filter((item) => {
                        return !rows.includes(item)
                    })
                useApplication.forceRerender()
            },
            () => {},
            confirmMessage
        )
    },
    /**
     * Executa o comportamento padrão do click no botão de criar da grid online, redireciona para a rota de create
     * @param event o evento do click
     */
    executeDefaultGridOnlineCreateButtonClick: (event) => {
        if (useApplication.userCanStore()) {
            getInertiaRouter().get(
                route(useApplication.applicationRoutes['create'].name)
            )
        }
    },
    /**
     * Executa o comportamento padrão do click no botão de excluir da grid online, redireciona para a rota de destroy ou multiple_destroy
     * @param event o evento do click
     * @param rows as linhas selecionadas
     */
    executeDefaultGridOnlineDestroyButtonClick: (event, rows) => {
        if (rows.length === 0) return
        const ids = toCommaSeparateString(rows, 'id')
        let routeName =
            rows.length === 1
                ? useApplication.applicationRoutes['destroy'].name
                : useApplication.applicationRoutes['multiple_destroy'].name
        let message =
            rows.length === 1
                ? 'Deseja realmente excluir o registro selecionado?'
                : 'Deseja realmente excluir os registros selecionados?'
        showConfirm(
            () => {
                getInertiaRouter().delete(route(routeName, { id: ids }), {
                    onSuccess: () => {
                        useApplication.forceRerender()
                    }
                })
            },
            () => {},
            message
        )
    },
    /**
     * Executa o comportamento padrão do click na linha da grid online, redireciona para a rota de show caso o usuário tenha permissão   *
     * @param event o evento do click
     */
    executeDefaultGridOnlineRowClick: (event) => {
        if (useApplication.userCanShow()) {
            getInertiaRouter().get(
                route(useApplication.applicationRoutes['show'].name, {
                    id: event.row.id
                })
            )
        }
    },
    /**
     * Verifica se existe algum erro de validação na sessão
     * @param errorsNamesList lista de nomes dos campos que devem ser verificados
     * @returns {boolean} true se existir algum erro, false se não existir
     */
    checkErrorsOnFormSession: (errorsNamesList) => {
        let errorsFound = errorsNamesList?.filter((fieldName) => {
            return useApplication.form?.errors[fieldName]
        })

        if (errorsFound?.length === 0) {
            const errors = Object.keys(useApplication.form?.errors)
            errors.forEach((error) => {
                if (error.includes('.')) {
                    const errorName = error.split('.')[0]
                    if (
                        errorsNamesList.includes(errorName) &&
                        useApplication.form?.errors[error] !== undefined
                    ) {
                        errorsFound.push(errorName)
                    }
                }
            })
        }

        return errorsFound?.length > 0
    },
    /**
     * Retorna o estilo de uma tab, recebe como parâmetro um array com os nomes dos campos que devem ser verificados
     * Método para retornar o estilo da sessão quando houver erros na sessão
     * @param errorsNamesList lista de nomes dos campos que devem ser verificados
     * @returns {string} retorna o estilo da sessão
     */
    getStyleFormSessionForErrorsList: (errorsNamesList) => {
        const errorsFound =
            useApplication.checkErrorsOnFormSession(errorsNamesList)
        return errorsFound ? '#dd1313' : ''
    },
    /**
     * Retorna o estilo com erro de uma tab
     * @param errorsNamesList lista de nomes dos campos que devem ser verificados
     * @returns {string|boolean} retorna o estilo da tab
     */
    getStyleNameTabForErrorsList: (errorsNamesList) => {
        const errorsFound =
            useApplication.checkErrorsOnFormSession(errorsNamesList)
        return errorsFound ? 'red' : false
    },
    /**
     * Retorna a rota de index
     * @param name o nome da rota, caso não seja passado, será usado o nome da rota de index
     * @returns {*} retorna a rota de index ou undefined
     */
    getIndexRoute: (name = '') => {
        return name.trim().length === 0
            ? useApplication.applicationRoutes['index']?.name
            : useApplication.applicationRoutes[name]?.name
    },
    /**
     * Retorna a rota de show
     * @param name o nome da rota, caso não seja passado, será usado o nome da rota de show
     * @returns {*} retorna a rota de show ou undefined
     */
    getShowRoute: (name = '') => {
        return name.trim().length === 0
            ? useApplication.applicationRoutes['show']?.name
            : useApplication.applicationRoutes[name]?.name
    },
    /**
     * Retorna a rota de create
     * @param name o nome da rota, caso não seja passado, será usado o nome da rota de create
     * @returns {*} retorna a rota de create ou undefined
     */
    getCreateRoute: (name = '') => {
        return name.trim().length === 0
            ? useApplication.applicationRoutes['create']?.name
            : useApplication.applicationRoutes[name]?.name
    },
    /**
     * Retorna a rota de store
     * @param name o nome da rota, caso não seja passado, será usado o nome da rota de store
     * @returns {*} retorna a rota de store ou undefined
     */
    getStoreRoute: (name = '') => {
        return name.trim().length === 0
            ? useApplication.applicationRoutes['store']?.name
            : useApplication.applicationRoutes[name]?.name
    },
    /**
     * Retorna a rota de update
     * @param name o nome da rota, caso não seja passado, será usado o nome da rota de update
     * @returns {*} retorna a rota de update ou undefined
     */
    getUpdateRoute: (name = '') => {
        return name.trim().length === 0
            ? useApplication.applicationRoutes['update']?.name
            : useApplication.applicationRoutes[name]?.name
    },
    /**
     * Retorna a rota de destroy
     * @param name o nome da rota, caso não seja passado, será usado o nome da rota de destroy
     * @returns {*} retorna a rota de destroy ou undefined
     */
    getDestroyRoute: (name = '') => {
        return name.trim().length === 0
            ? useApplication.applicationRoutes['destroy']?.name
            : useApplication.applicationRoutes[name]?.name
    },
    /**
     * Retorna a rota atual
     * @returns {string} retorna a rota atual
     */
    getCurrentRoute: () => {
        return route().current()
    },
    /**
     * Verifica se a rota atual é a de show
     * @returns {boolean} retorna true se a rota atual é a de show, false se não é
     */
    currentRouteIsShowRoute: () => {
        return (
            useApplication.getCurrentRoute() === useApplication.getShowRoute()
        )
    },
    /**
     * Verifica se a rota atual é a de create
     * @returns {boolean} retorna true se a rota atual é a de create, false se não é
     */
    currentRouteIsCreateRoute: () => {
        return (
            useApplication.getCurrentRoute() === useApplication.getCreateRoute()
        )
    },
    /**
     * Retorna a rota de multiple_destroy
     * @returns {*} retorna a rota de multiple_destroy ou undefined
     */
    getMultipleDestroyRoute: () => {
        return useApplication.applicationRoutes['multiple_destroy']?.name
    },
    /**
     * Executa o comportamento padrão do formulário para atualizar, redireciona para a rota de update
     * @param fabMainLabel o label do botão de atualizar
     * @param confirmMessage a mensagem de confirmação
     */
    executeDefaultFormFabMainUpdate: (
        fabMainLabel = 'Salvar',
        confirmMessage = 'Deseja Salvar?'
    ) => {
        useApplication.form.showing = true
        useApplication.form.fab.main.color = 'orange'
        useApplication.form.fab.main.icon = 'edit'
        useApplication.form.fab.main.show = !useApplication.formInOnlyViewMode()
        useApplication.form.fab.main.label = fabMainLabel
        useApplication.form.fab.main.action = () => {
            if (useApplication.hasSubFormsOpen()) {
                showWarningNotify(useApplication.getOpenSubFormsMessage())
            } else {
                showConfirm(
                    () => {
                        if (useApplication.userCanUpdate()) {
                            getInertiaRouter().put(
                                route(useApplication.getUpdateRoute(), {
                                    id: useApplication.form.inertiaForm.id
                                }),
                                useApplication.form.inertiaForm.data()
                            )
                        }
                    },
                    () => {},
                    confirmMessage
                )
            }
        }
    },
    /**
     * Executa o comportamento padrão do formulário para criar
     * @param fabMainLabel o label do botão criar
     * @param confirmMessage a mensagem de confirmação
     */
    executeDefaultFormFabMainStore: (
        fabMainLabel = 'Criar',
        confirmMessage = 'Deseja Criar?'
    ) => {
        useApplication.form.fab.main.show = true
        useApplication.form.fab.main.label = fabMainLabel
        useApplication.form.fab.main.action = () => {
            if (useApplication.hasSubFormsOpen()) {
                showWarningNotify(useApplication.getOpenSubFormsMessage())
            } else {
                showConfirm(
                    () => {
                        if (useApplication.userCanStore()) {
                            getInertiaRouter().post(
                                route(useApplication.getStoreRoute()),
                                useApplication.form.inertiaForm.data()
                            )
                        }
                    },
                    () => {},
                    confirmMessage
                )
            }
        }
    },
    /**
     * Limpa os erros de um campo ou sessão, recebe como parâmetro o nome do campo ou sessão
     * @param name
     */
    clearFormErrorsFor: (name) => {
        if (useApplication.form.errors[name]) {
            useApplication.form.errors[name] = undefined
        } else {
            const errors = Object.keys(useApplication.form?.errors)
            errors.forEach((error, index) => {
                if (error.includes('.')) {
                    const errorName = error.split('.')[0]
                    if (errorName === name) {
                        useApplication.form.errors[error] = undefined
                    }
                }
            })
        }
    },
    /**
     * Adiciona um formulário filho que está aberto, recebe como parâmetro o nome do formulário
     * @param name o nome do formulário
     */
    addOpenSubForm: (name) => {
        useApplication.form.openSubForms?.push(name)
    },
    /**
     * Remove um formulário filho que está aberto, recebe como parâmetro o nome do formulário
     * @param name o nome do formulário
     */
    removeOpenSubForm: (name) => {
        const index = useApplication.form.openSubForms?.indexOf(name)
        if (index > -1) {
            useApplication.form.openSubForms?.splice(index, 1)
        }
    },
    /**
     * Verifica se existe algum formulário filho aberto
     * @returns {boolean} true se existir algum formulário filho aberto, false se não existir
     */
    hasSubFormsOpen: () => {
        return useApplication.form.openSubForms?.length > 0
    },
    /**
     * Retorna a mensagem que alerta que existem formulários filhos abertos
     * @returns {string} retorna a mensagem
     */
    getOpenSubFormsMessage: () => {
        let message = ''
        if (useApplication.form.openSubForms?.length === 1) {
            const openSubForm = useApplication.form.openSubForms[0]
            message = `Existe ação pendente no formulário: ${openSubForm}`
        } else {
            message = 'Existem ações pendentes nos seguintes formulários: '
            useApplication.form.openSubForms.forEach((form) => {
                message += `${form} - `
            })
            message = message.substring(0, message.length - 3)
        }

        return message
    },
    /**
     * Aplica os parâmetros do sistema
     */
    applyParameters: () => {
        useApplication.parameters = getCurrentParameters()
        useApplication.parameters.apply()
    },
    /**
     * Adiciona um botão de ação no FAB (Floating Action Button), recebe como parâmetro o nome do botão, o ícone, a cor, o label e a ação
     * @param color a cor do botão
     * @param icon o ícone do botão
     * @param show se o botão deve ser exibido
     * @param label o label do botão
     * @param action a ação do botão
     */
    addFabOther: (color, icon, show, label, action) => {
        useApplication.form.fab.others.push({
            color: color,
            icon: icon,
            show: show,
            label: label,
            action: action
        })
    },
    /**
     * Esconde o Loading do Quasar
     */
    hideLoading: () => {
        Loading.hide()
    },
    /**
     * Exibe o Loading do Quasar
     */
    showLoading: () => {
        Loading.show({
            spinner: QSpinnerTail,
            delay: 400 // ms
        })
    },
    /**
     * Atualiza o objeto do Inertia para controle do formulário, recebe como parâmetro o objeto de dados da página, o padrão é o objeto data da página atual
     */
    refreshDataForm: (data = getCurrentData()) => {
        useApplication.form.inertiaForm = useForm(data)
    },
    /**
     * Updates the Inertia form data for the specified field.
     *
     * @param {string} field - The field to update
     * @return {void}
     */
    updateInertiaFormData: (field) => {
        useApplication.clearFormErrorsFor(field)
        useApplication.refreshDataForm(
            Object.assign(
                {
                    [field]: useApplication.form.inertiaForm[field]
                },
                useApplication.form.inertiaForm.data()
            )
        )
    },
    /**
     * Generate current breadcrumbs based on the provided route current and last breadcrumbs.
     *
     * @param {array} lastCrumbs - The last breadcrumbs
     * @param {number} currentRoute - The current of the route
     * @return {void}
     */
    buildCurrentCrumbs: (lastCrumbs, currentRoute) => {
        let crumbs = []
        if (!currentRoute) {
            currentRoute = useApplication.getIndexRoute()
        }
        getCurrentProps().menu.map((item) => {
            if (item.route_navigate === currentRoute) {
                crumbs.push(item)
            }
            if (item.sub_menus.length > 0) {
                item.sub_menus.forEach((subItem) => {
                    if (subItem.route_navigate === currentRoute) {
                        crumbs.push(item)
                        crumbs.push({
                            label: subItem.label,
                            icon: subItem.icon,
                            route: subItem.route_navigate
                        })
                    }
                })
            }
        })
        if (crumbs.length > 0 && lastCrumbs) {
            if (lastCrumbs !== crumbs[crumbs.length - 1].label) {
                let icon = 'add'
                if (useApplication.currentRouteIsShowRoute()) {
                    icon = useApplication.getShowIcon()
                }
                crumbs.push({
                    label: lastCrumbs,
                    icon: icon
                })
            }
        }
        useApplication.setCrumbs(crumbs)
    },
    getShowIcon: () => {
        return useApplication.formInOnlyViewMode() ? 'visibility' : 'edit'
    },
    setCrumbs: (crumbs) => {
        useApplication.crumbs = crumbs
    },
    updateCombo: (filter, routeName) => {
        return new Promise((resolve, reject) => {
            axios
                .get(route(routeName) + `?filter=${filter}`)
                .then((response) => {
                    resolve(response)
                })
                .catch(() => {
                    reject([])
                })
        })
    }
})
