import { Dialog, Notify } from 'quasar'

export const showConfirm = (
    functionOnOk,
    functionOnCancel,
    message = 'Deseja realizar a ação?',
    title = 'Confirmação'
) => {
    Dialog.create({
        title: title,
        message: message,
        cancel: true,
        persistent: true
    })
        .onOk(() => {
            functionOnOk()
        })
        .onCancel(() => {
            functionOnCancel()
        })
}

export const showMessage = (title, message) => {
    Dialog.create({
        title: title,
        message: message,
        cancel: false,
        persistent: true
    })
}

export const showWarningNotify = (message) => {
    Notify.create({
        message: message,
        color: 'warning',
        position: 'top',
        multiLine: true,
        icon: 'warning',
        progress: true
    })
}

//colors: 'positive', 'negative', 'warning', 'info'
export const showPositiveNotify = (message) => {
    Notify.create({
        message: message,
        color: 'positive',
        position: 'top',
        multiLine: true,
        icon: 'check_circle',
        progress: true
    })
}

export const showNegativeNotify = (message) => {
    Notify.create({
        message: message,
        color: 'negative',
        position: 'top',
        multiLine: true,
        icon: 'error',
        progress: true
    })
}

export const showInfoNotify = (message) => {
    Notify.create({
        message: message,
        color: 'info',
        position: 'top',
        multiLine: true,
        icon: 'info',
        progress: true
    })
}
