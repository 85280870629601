import { Dark, setCssVar } from 'quasar'

export default class Parameter {
    constructor(attributes = {}) {
        this.isDarkMode = attributes['dark_mode_enable'] === '1'
        this.primaryColor = attributes['primary_color']
        this.secondaryColor = attributes['secondary_color']
        this.accentColor = attributes['accent_color']
        this.positiveColor = attributes['positive_color']
        this.negativeColor = attributes['negative_color']
        this.infoColor = attributes['info_color']
        this.warningColor = attributes['warning_color']
        this.menuColor = attributes['menu_color']
        this.menus = attributes['menus']
        this.dense = attributes['dense_mode_enable'] === '1'
    }

    apply() {
        Dark.set(this.isDarkMode)
        setCssVar('primary', this.primaryColor)
        setCssVar('secondary', this.secondaryColor)
        setCssVar('accent', this.accentColor)
        setCssVar('positive', this.positiveColor)
        setCssVar('negative', this.negativeColor)
        setCssVar('info', this.infoColor)
        setCssVar('warning', this.warningColor)
    }
}
