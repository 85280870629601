<script setup>
import route from '@/Utils/ZiggyRoute'
import { userCan } from '@/Utils/CheckPermission'
import { useLeftMenu } from '@/Composables/UseLeftMenu'
import {
    getCurrentMenus,
    getCurrentParameters,
    getCurrentTenant,
    getInertiaRouter
} from '@/Utils/InertiaResponse'
import { useApplication } from '@/Composables/UseApplication'
import { onMounted } from 'vue'

const menuItems = getCurrentMenus()

const isAuthenticationServer = getCurrentTenant().isAuthenticationServer

const currentRouteIs = (routeName) => {
    return route().current(routeName)
}

const openMenuCurrent = () => {
    menuItems.forEach((element) => {
        if (element.sub_menus && element.sub_menus.length > 0) {
            element.sub_menus.forEach((subMenu) => {
                if (
                    subMenu.route_navigate === useApplication.getCurrentRoute()
                ) {
                    element.open = true
                }
            })
        }
    })
}

const menuClick = (event, go) => {
    getInertiaRouter().get(go)
}

onMounted(() => {
    openMenuCurrent()
})
</script>

<template>
    <q-drawer id="menu" v-model="useLeftMenu.state" bordered show-if-above>
        <q-list inset-separator link no-border>
            <q-item dusk="menu">
                <q-item-section> Menu</q-item-section>
            </q-item>
        </q-list>

        <q-list
            v-for="menu in menuItems"
            :key="menu.id"
            inset-separator
            link
            no-border
        >
            <!-- Menu único, renderiza quando
                - Não tem submenus e, ou o menu não tem permissões setadas ou o usuário tem as permissões necessárias
                - E o menu não é para o servidor de autenticação, ou o menu é para o servidor de autenticação e o usuário está no servidor de autenticação
            -->
            <template
                v-if="
                    menu.sub_menus.length === 0 &&
                    (menu.permissions.length === 0 || userCan(menu.permissions))
                "
            >
                <q-item
                    v-if="
                        !menu.for_authentication_server ||
                        (menu.for_authentication_server &&
                            isAuthenticationServer)
                    "
                    v-ripple
                    :active="
                        menu.route_activate
                            ? currentRouteIs(menu.route_activate)
                            : undefined
                    "
                    :dense="useApplication.parameters.dense"
                    :dusk="menu.id"
                    active-class="bg-accent text-white"
                    clickable
                    @click="menuClick($event, route(menu.route_navigate))"
                >
                    <q-tooltip anchor="center right" self="center left">
                        {{ menu.label }}
                    </q-tooltip>
                    <q-item-section avatar>
                        <q-icon :name="menu.icon" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{ menu.label }}</q-item-label>
                    </q-item-section>
                </q-item>
            </template>

            <!--
                Menu composto, renderiza quando
                    - Tem submenus e, usuário tem as permissões necessárias
                    - E o menu não é para o servidor de autenticação, ou o menu é para o servidor de autenticação e o usuário está no servidor de autenticação
            -->
            <template v-else-if="userCan(menu.permissions)">
                <q-list
                    v-if="
                        !menu.for_authentication_server ||
                        (menu.for_authentication_server &&
                            isAuthenticationServer)
                    "
                    inset-separator
                    link
                    no-border
                >
                    <q-expansion-item
                        :id="menu.id"
                        v-model="menu.open"
                        :content-inset-level="0.3"
                        :dense="getCurrentParameters().dense"
                        :dusk="menu.id"
                        :icon="menu.icon"
                        :label="menu.label"
                        expand-separator
                    >
                        <template
                            v-for="subMenu in menu.sub_menus"
                            :key="subMenu.id"
                        >
                            <template
                                v-if="
                                    !subMenu.for_authentication_server ||
                                    (subMenu.for_authentication_server &&
                                        isAuthenticationServer)
                                "
                            >
                                <q-item
                                    v-if="
                                        userCan(subMenu.permissions) &&
                                        !subMenu.sub_menus_settings
                                    "
                                    :id="subMenu.id"
                                    v-ripple
                                    :active="
                                        subMenu.route_activate
                                            ? currentRouteIs(
                                                  subMenu.route_activate
                                              )
                                            : undefined
                                    "
                                    :dense="getCurrentParameters().dense"
                                    :dusk="subMenu.id"
                                    active-class="bg-accent text-white"
                                    clickable
                                    @click="
                                        menuClick(
                                            $event,
                                            route(subMenu.route_navigate)
                                        )
                                    "
                                >
                                    <q-tooltip
                                        anchor="center right"
                                        self="center left"
                                    >
                                        {{ subMenu.label }}
                                    </q-tooltip>
                                    <q-item-section avatar>
                                        <q-icon :name="subMenu.icon" />
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label>{{
                                            subMenu.label
                                        }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-expansion-item
                                    v-if="subMenu.sub_menus_settings"
                                    :id="subMenu.id"
                                    v-model="subMenu.sub_menus_settings.open"
                                    :content-inset-level="0.3"
                                    :dense="getCurrentParameters().dense"
                                    :dusk="subMenu.id"
                                    :icon="subMenu.icon"
                                    :label="subMenu.label"
                                    expand-separator
                                >
                                    <q-list
                                        v-for="sub in subMenu.sub_menus_settings"
                                        :key="sub.id"
                                        inset-separator
                                        link
                                        no-border
                                    >
                                        <q-item
                                            v-if="
                                                userCan(sub.permissions) &&
                                                !sub.sub_menus_settings
                                            "
                                            :id="sub.id"
                                            v-ripple
                                            :active="
                                                sub.route_activate
                                                    ? currentRouteIs(
                                                          sub.route_activate
                                                      )
                                                    : undefined
                                            "
                                            :dense="
                                                getCurrentParameters().dense
                                            "
                                            :dusk="sub.id"
                                            active-class="bg-accent text-white"
                                            clickable
                                            @click="
                                                menuClick(
                                                    $event,
                                                    route(sub.route_navigate)
                                                )
                                            "
                                        >
                                            <q-tooltip
                                                anchor="center right"
                                                self="center left"
                                            >
                                                {{ sub.label }}
                                            </q-tooltip>
                                            <q-item-section avatar>
                                                <q-icon :name="sub.icon" />
                                            </q-item-section>
                                            <q-item-section>
                                                <q-item-label>{{
                                                    sub.label
                                                }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                </q-expansion-item>
                                <q-separator />
                            </template>
                        </template>
                    </q-expansion-item>
                </q-list>
            </template>
        </q-list>
    </q-drawer>
</template>
