<script setup>
import { ref } from 'vue'
import {
    getCurrentSystem,
    getCurrentTenant,
    getCurrentUserTenantSpaces,
    getCurrentVersion
} from '@/Utils/InertiaResponse'

const tenantObject = ref(getCurrentTenant().url)

const version = getCurrentVersion()
const system = getCurrentSystem()

const navigateToTenant = (url) => {
    window.open(url, '_blank')
}
</script>

<template>
    <q-footer
        :class="$q.dark.isActive ? 'bg-dark' : 'bg-primary'"
        :reveal="true"
        elevated
    >
        <q-toolbar :class="['text-white rounded-borders']">
            <q-toolbar-title>
                <div id="tenant-picker-wrapper">
                    <div id="tenant-picker-container" class="row">
                        <q-select
                            v-model="tenantObject"
                            :label="system.name + ' ( ' + version + ' )'"
                            :options="getCurrentUserTenantSpaces()"
                            :value="getCurrentTenant().url"
                            color="orange"
                            dark
                            dusk="selectChangeTenant"
                            style="font-size: 16px"
                            @update:model-value="navigateToTenant($event)"
                        >
                        </q-select>
                    </div>
                </div>
            </q-toolbar-title>
        </q-toolbar>
    </q-footer>
</template>
