<script setup>
import SharedNav from '@/Shared/Layout/LayoutNav.vue'
import SharedHeader from '@/Shared/Layout/LayoutHeader.vue'
import LayoutFooter from '@/Shared/Layout/LayoutFooter.vue'
import route from '@/Utils/ZiggyRoute'
import LayoutFooterMenuExternalVue from './LayoutFooterMenuExternal.vue'
import { onMounted } from 'vue'
import { useAuth } from '@/Composables/UseAuth'
import { getCurrentProps } from '@/Utils/InertiaResponse'

let externalAccess = route().current('souprodigital.external.*')
onMounted(() => {
    useAuth.user = getCurrentProps().auth.user
})
</script>
<template>
    <q-layout view="lHh LpR fFf">
        <div v-if="externalAccess">
            <q-page-container>
                <slot />
            </q-page-container>
            <LayoutFooterMenuExternalVue />
        </div>
        <div v-else>
            <SharedHeader />
            <SharedNav />
            <q-page-container>
                <slot />
            </q-page-container>
            <LayoutFooter />
        </div>
    </q-layout>
</template>
