import { createApp, h } from 'vue'
import { createInertiaApp, Head, Link, router } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
//Quasar imports
import { Dialog, Loading, LocalStorage, Notify, Quasar } from 'quasar'
import langPtBr from 'quasar/lang/pt-BR'
// Quasar icon libraries
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'
// Quasar css
import 'quasar/src/css/index.sass'
//Layout
import Layout from './Shared/Layout/LayoutMain.vue'

//Plugins
import { notifications } from '@/Plugins/Notification'
//Composables
import { useApplication } from '@/Composables/UseApplication'
//Utils
import { getCurrentProps, getCurrentSystem } from '@/Utils/InertiaResponse'
//Images folder
import.meta.glob(['../images/**'])

createInertiaApp({
    id: 'q-app',
    resolve: async (name) => {
        // Resolve the page component asynchronously
        const page = await resolvePageComponent(
            `./Pages/${name}.vue`,

            import.meta.glob('./Pages/**/*.vue')
        )
        // Add the layout to the page component if there is no default layout set
        if (page.default.layout === undefined) {
            page.default.layout = Layout
        }
        // Return the page component
        return page
    },
    progress: {
        // The delay after which the progress bar will appear, in milliseconds...
        delay: 500,
        // The color of the progress bar...
        color: 'green',
        // Whether to include the default NProgress styles...
        includeCSS: true,
        // Whether the NProgress spinner will be shown...
        showSpinner: true
    },
    setup({ el, App, props, plugin }) {
        createApp({
            render: () => h(App, props)
        })
            .use(plugin)
            .use(Quasar, {
                config: {
                    brand: {}
                },
                lang: langPtBr,
                plugins: {
                    Dialog,
                    Loading,
                    Notify,
                    LocalStorage
                } // import Quasar plugins and add here
            })
            .use(notifications)
            .component('Link', Link) //Global component registration
            .component('Head', Head) //Global component registration
            .mount(el)
    },
    title: (title) =>
        getCurrentSystem().name
            ? `${title} - ${getCurrentSystem().name}`
            : title //Definindo o titulo da página dinamicamente
})

//Eventos do Inertia.js. Para detalhes ver documentação.
// Ordem dos eventos:
// before
// start
// navigate
// success
// finish

// router.on('before', (start) => {
//     showLoading()
//     console.log('before')
// })

router.on('start', (start) => {
    useApplication.showLoading()
})

// router.on('navigate', (start) => {
//     console.log('navigate')
// })

// router.on('success', (success) => {
//     hideLoading()
//     // console.log('success')
//     // Notify.create({
//     //     message: 'Sucesso!',
//     //     color: 'positive',
//     //     position: 'top'
//     // })
// })

router.on('finish', (success) => {
    useApplication.form.errors = getCurrentProps().errors
    useApplication.hideLoading()
})

// router.on('exception', (start) => {
//     showLoading()
//     console.log('exception')
// })
//
// router.on('invalid', (start) => {
//     showLoading()
//     console.log('invalid')
// })
//
// router.on('progress', (start) => {
//     showLoading()
//     console.log('progress')
// })

// router.on('error', (errors) => {
//     hideLoading()
//
//     //Formatando as mensagens de erro
//     let errorsObj = errors.detail.errors
//     let errorsMessages = ''
//
//     for (let key in errorsObj) {
//         errorsMessages = errorsMessages + ' ' + errorsObj[key]
//     }
//
//     errorsMessages = errorsMessages.trim()
//
//     Notify.create({
//         message: errorsMessages,
//         color: 'negative',
//         multiLine: true,
//         position: 'top'
//     })
// })
