<script setup>
import { useLeftMenu } from '@/Composables/UseLeftMenu'
import { useQuasar } from 'quasar'
import route from '@/Utils/ZiggyRoute'
import { getCurrentSystem, getInertiaRouter } from '@/Utils/InertiaResponse'
import ComponentsBreadcrumbs from '@/Shared/Components/ComponentsBreadcrumbs.vue'
import { useApplication } from '@/Composables/UseApplication'
import { useAuth } from '@/Composables/UseAuth'
import { ref } from 'vue'
import { userCan } from '@/Utils/CheckPermission'

const system = getCurrentSystem()
const router = getInertiaRouter()

const $q = useQuasar()

const search = ref('')

const navigateTo = function (routeTo) {
    getInertiaRouter().get(route(routeTo))
}

const logout = function () {
    $q.dialog({
        title: `Saindo...`,
        message: 'Deseja sair?',
        cancel: true
    }).onOk(() => {
        router.post(
            route('logout'),
            {},
            {
                preserveState: true,
                preserveScroll: true
            }
        )
    })
}
</script>

<template>
    <q-header :class="$q.dark.isActive ? 'bg-dark' : 'bg-primary'">
        <q-toolbar>
            <q-btn
                :dense="useApplication.parameters.dense"
                aria-label="Menu"
                class="q-mr-sm"
                flat
                icon="menu"
                round
                @click="useLeftMenu.toggle()"
            />

            <q-toolbar-title v-if="$q.screen.gt.xs" class="text-center" shrink>
                <q-avatar>
                    <q-icon name="engineering"></q-icon>
                </q-avatar>
                {{ system.name }}
            </q-toolbar-title>
            <q-toolbar-title v-if="$q.screen.gt.xs">
                <q-input
                    v-model="search"
                    class="col-xs-12"
                    dark
                    dense
                    label="Pesquisar"
                    standout
                    type="text"
                >
                    <template #append>
                        <q-icon v-if="search === ''" name="search" />
                        <q-icon
                            v-else
                            class="cursor-pointer"
                            name="clear"
                            @click="search = ''"
                        />
                    </template>
                </q-input>
            </q-toolbar-title>

            <q-space v-if="!$q.screen.gt.xs" />

            <q-btn
                class="q-mt-sm q-mr-sm q-mb-sm"
                dense
                flat
                icon="chat"
                round
                title="Novas Mensagens"
            >
                <q-badge color="positive" floating>{{ 0 }}</q-badge>
            </q-btn>
            <q-btn
                class="q-mt-sm q-mr-sm q-mb-sm"
                dense
                flat
                icon="notification_important"
                round
                title="Novas Notificações"
            >
                <q-badge color="info" floating>{{ 0 }}</q-badge>
            </q-btn>

            <div>
                <q-btn round>
                    <q-avatar v-if="useAuth.user.avatar">
                        <q-img :src="useAuth.user.avatar" fit="fill" />
                    </q-avatar>
                    <q-avatar v-else icon="person" rounded />
                    <q-menu>
                        <div class="row no-wrap q-pa-md">
                            <div class="column items-center">
                                <q-btn
                                    v-close-popup
                                    :disable="
                                        userCan([
                                            'profile.show',
                                            'profile.update'
                                        ]) === false
                                    "
                                    :ripple="false"
                                    flat
                                    round
                                    unelevated
                                    @click="navigateTo('profile.show')"
                                >
                                    <q-tooltip anchor="center left"
                                        >Meu Perfil</q-tooltip
                                    >
                                    <q-avatar
                                        v-if="useAuth.user.avatar"
                                        size="72px"
                                    >
                                        <q-img
                                            :src="useAuth.user.avatar"
                                            fit="fill"
                                        />
                                    </q-avatar>
                                    <q-avatar v-else icon="person" rounded />
                                </q-btn>

                                <div class="text-subtitle1 q-mt-md q-mb-xs">
                                    {{ useAuth.user.name }}
                                </div>

                                <q-btn
                                    v-close-popup
                                    color="primary"
                                    label="Sair"
                                    push
                                    size="sm"
                                    @click="logout()"
                                />
                            </div>
                        </div>
                    </q-menu>
                </q-btn>
            </div>
        </q-toolbar>
        <q-toolbar>
            <ComponentsBreadcrumbs />
        </q-toolbar>
    </q-header>
</template>
