import {
    getCurrentUser,
    getCurrentUserPermissions
} from '@/Utils/InertiaResponse'

export const userCan = (permissions) => {
    const userPermissions = getCurrentUserPermissions()
    if (getCurrentUser().admin) {
        return true
    } else {
        const userHasPermission = userPermissions?.filter((element) => {
            return permissions?.includes(element)
        })
        return userHasPermission.length > 0
    }
}
