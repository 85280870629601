export default class Pagination {
    constructor(attributes = {}) {
        this.currentPage = attributes.current_page
        this.data = attributes.data
        this.firstPageUrl = attributes.first_page_url
        this.from = attributes.from
        this.lastPage = attributes.last_page
        this.lastPageUrl = attributes.last_page_url
        this.links = attributes.links
        this.nextPageUrl = attributes.next_page_url
        this.path = attributes.path
        this.perPage = attributes.per_page
        this.prevPageUrl = attributes.prev_page_url
        this.to = attributes.to
        this.total = attributes.total
    }
}
