export default class Tenant {
    constructor(attributes = {}) {
        this.id = attributes.id
        this.url = attributes.url
        this.name = attributes.name
        this.initials = attributes.initials
        this.email = attributes.email
        this.isAuthenticationServer = attributes.is_authentication_server
    }
}
