import { router } from '@inertiajs/vue3'

import { getCurrentProps } from '@/Utils/InertiaResponse'
import {
    showInfoNotify,
    showNegativeNotify,
    showPositiveNotify,
    showWarningNotify
} from '@/Utils/Message'

/**
 * Plugin que exibe as notificações da aplicação
 */
export const notifications = () => {
    router.on('finish', () => {
        const notification = getCurrentProps().notification
        if (notification) {
            switch (notification.type) {
                case 'success':
                    showPositiveNotify(notification.body)
                    break
                case 'error':
                    showNegativeNotify(notification.body)
                    break
                case 'warning':
                    showWarningNotify(notification.body)
                    break
                case 'info':
                    showInfoNotify(notification.body)
                    break
                default:
                    showPositiveNotify(notification.body)
            }
        }
    })
}
