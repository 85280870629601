export default class User {
    constructor(attributes = {}) {
        this.id = attributes.id
        this.name = attributes.name
        this.admin = attributes.admin
        this.email = attributes.email
        this.avatar = attributes.avatar
        this.last_login = attributes.last_login
        this.permissions = attributes.permissions
        this.tenant_spaces = attributes.tenant_spaces
    }
}
