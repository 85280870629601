/**
 * @param arrayOfObjects A array of Objects. Ex.: [{id:1, name: 'Teste'}, {id:s, name: 'Teste'}]
 * @param key The name of object key to separate with comma string. Ex.: 'id'
 * @returns {string} A string with separate comma values. Ex.: 1,2,3
 */
export const toCommaSeparateString = (arrayOfObjects, key) => {
    if (!(arrayOfObjects instanceof Array)) {
        return ''
    }
    return arrayOfObjects
        .map((item) => {
            return item[key]
        })
        .join(',')
}

/**
 * Filter array of objects for a array of strings
 * @param arrayOfObjects {Array} A array of Objects. Ex.: [{id:1, name: 'Teste'}, {id:s, name: 'Teste'}]
 * @param arrayOfObjectsFilterKey {string} The name of object key to filter. Ex.: 'id'
 * @param arrayOfStrings {Array} A array of Strings. Ex.: ['1', '2', '3']
 * @returns {Array} A array of Objects filtered. Ex.: [{id:1, name: 'Teste'}, {id:s, name: 'Teste'}]
 */
export const filterArrayOfObjectsForArrayOfStrings = (
    arrayOfObjects,
    arrayOfObjectsFilterKey,
    arrayOfStrings
) => {
    return arrayOfObjects.filter((aoEl) => {
        return arrayOfStrings?.some((asEl) => {
            return asEl === aoEl[arrayOfObjectsFilterKey]
        })
    })
}
